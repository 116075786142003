import React, {useCallback, useMemo} from 'react';

import {Box, Typography} from '@mui/material';
import {TimePicker} from '@mui/x-date-pickers';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {EditContent} from '@/molecules/WeeklySchedule/EditContent';
import {EmptyOpeningHours} from '@/molecules/WeeklySchedule/EmptyOpeningHours';

import {AddButton} from './AddButton';
import {useStyle} from './style';
import {WeekDayInterval, WeeklyScheduleProps} from './type';

const WeeklySchedule = ({
  day,
  handleChange,
  timezone,
  isLoading,
  disabled = false,
}: WeeklyScheduleProps) => {
  const {
    boxContainer: boxContainerStyle,
    boxContent: boxContentStyle,
    boxWrapperSlotTimes: boxWrapperSlotTimesStyle,
    day: dayStyle,
    wrapperButtons: wrapperButtonsStyle,
    timePicker: timePickerStyle,
    deleteSlot: deleteSlotStyle,
    deleteSlotFullsize: deleteSlotFullsizeStyle,
  } = useStyle();

  const {t} = useTranslations();

  const incrementSlotChangeHandler = useCallback(() => {
    handleChange({
      ...day,
      intervals: [
        ...day.intervals,
        {
          start_time: null,
          end_time: null,
        },
      ],
    });
  }, [day, handleChange]);

  const decrementSlotChangeHandler = useCallback(
    (index: number) => {
      handleChange({
        ...day,
        intervals: day.intervals.filter((_, i) => {
          return i !== index;
        }),
      });
    },
    [day, handleChange],
  );

  const timeChangeHandler = useCallback(
    (value: any, index: number, timeKey: 'end_time' | 'start_time') => {
      handleChange({
        ...day,
        intervals: day.intervals.map((dayInterval, i) => {
          if (i === index) {
            return {
              ...dayInterval,
              [timeKey]: value,
            };
          }
          return {...dayInterval};
        }),
      });
    },
    [day, handleChange],
  );

  /**
   * In the edit view, the opening hours are not editable.
   * To manage the time data received from the backend in the correct timezone, we display a specific component.
   * This component does not include a time picker and does not process time as strings.
   */
  const editContent = useMemo(() => {
    if (day.intervals.length === 0) {
      return (
        <EmptyOpeningHours
          disabled={disabled}
          isLoading={isLoading}
          incrementSlotChangeHandler={incrementSlotChangeHandler}
        />
      );
    }

    return day.intervals.map((dayInterval: WeekDayInterval, index: number) => {
      return (
        <EditContent
          key={index}
          disabled={disabled}
          isLoading={isLoading}
          day={day}
          dayInterval={dayInterval}
          index={index}
        />
      );
    });
  }, [day, disabled, isLoading, incrementSlotChangeHandler]);

  const content = useMemo(() => {
    if (day.intervals.length === 0) {
      return (
        <EmptyOpeningHours
          disabled={disabled}
          isLoading={isLoading}
          incrementSlotChangeHandler={incrementSlotChangeHandler}
        />
      );
    }

    return day.intervals.map((dayInterval: WeekDayInterval, index: number) => {
      return (
        <Box key={index} sx={boxWrapperSlotTimesStyle}>
          <TimePicker
            disabled={isLoading || disabled}
            label={t('EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.START_TIME')}
            minutesStep={30}
            minTime={index > 0 ? day.intervals[index - 1].end_time : undefined}
            skipDisabled={true}
            onChange={newValue =>
              timeChangeHandler(newValue, index, 'start_time')
            }
            value={dayInterval.start_time ?? null}
            timezone={timezone}
            sx={timePickerStyle}
          />
          <TimePicker
            disabled={isLoading || disabled}
            label={t('EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.END_TIME')}
            minutesStep={30}
            minTime={dayInterval.start_time ?? undefined}
            skipDisabled={true}
            onChange={newValue =>
              timeChangeHandler(newValue, index, 'end_time')
            }
            value={dayInterval.end_time ?? null}
            timezone={timezone}
            sx={timePickerStyle}
          />
          <Box sx={wrapperButtonsStyle}>
            {index === day.intervals.length - 1 && (
              <AddButton
                disabled={isLoading || disabled}
                handleClick={incrementSlotChangeHandler}
              />
            )}
            {/* DELETE BUTTON */}
            <Button
              disabled={isLoading || disabled}
              sx={
                index === day.intervals.length - 1
                  ? deleteSlotStyle
                  : deleteSlotFullsizeStyle
              }
              variant={'secondary'}
              color={'default'}
              onClick={() => decrementSlotChangeHandler(index)}>
              {t('EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.DELETE_SLOT')}
            </Button>
          </Box>
        </Box>
      );
    });
  }, [
    day.intervals,
    t,
    isLoading,
    disabled,
    incrementSlotChangeHandler,
    boxWrapperSlotTimesStyle,
    timezone,
    timePickerStyle,
    wrapperButtonsStyle,
    deleteSlotStyle,
    deleteSlotFullsizeStyle,
    timeChangeHandler,
    decrementSlotChangeHandler,
  ]);

  const body = useMemo(() => {
    // The opening hours are not editable in the edit view.
    return disabled ? editContent : content;
  }, [content, disabled, editContent]);

  return (
    <Box sx={boxContainerStyle}>
      <Typography variant="bodyRegular" sx={dayStyle}>
        {t(
          `EVENT_CREATE_EDIT.WEEKLY_SCHEDULE.WEEK_DAY_NUMBER.${day.week_day_number}`,
        )}
      </Typography>
      <Box sx={boxContentStyle}>{body}</Box>
    </Box>
  );
};
export default WeeklySchedule;
